import { Suspense, lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import Loader from './components/Loader';
import { useOptions } from './hooks';

const MainGOTS = lazy(() => import('./pages/GOTS/MainGOTS'));
const MainGOTx = lazy(() => import('./pages/GOTx/MainGOTx'));

export const App = () => {
  const { language, appType } = useOptions();
  const {
    i18n: { changeLanguage },
  } = useTranslation();

  const isGOTS = appType === 'gots';

  useEffect(() => {
    if (isGOTS) {
      import('./styles/gots.scss');
    } else {
      import('./styles/gotx.scss');
    }
  }, [isGOTS]);

  useEffect(() => {
    changeLanguage(language);
  }, [changeLanguage, language]);

  const Main = isGOTS ? MainGOTS : MainGOTx;

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route index element={<Main />} />
          <Route path='/:pollId?/:nomineeId?' element={<Main />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
