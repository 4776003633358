import './wdyr';
import { defineCustomElements } from '@uefadigital/panenka/dist/loader';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import packageInfos from '../package.json';

import App from './App';
import { Provider, store } from './store';
import { appEnv } from './utils/environment';
import { getAppOptions } from './utils/optionsManager';

if (window.definePanenka) {
  defineCustomElements();
}

const { version } = packageInfos;

console.log(`Running version: ${version} on ${appEnv}`);

const container = document.getElementById('gott');
const options = getAppOptions(container);
const root = createRoot(container);

root.render(
  <StrictMode>
    <Provider store={store} options={options}>
      <App />
    </Provider>
  </StrictMode>,
);
